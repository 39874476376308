/* !
 * jQuery index v1.0.0
 * Copyright 2019, Connecty Inc.
 */
(function($, NSFD){
	const a = 'is-active';
	const v = 'is-visible';
	const arrowPrev = '<button type="button" class="slick-prev"><svg class="icon"><use xlink:href="/index/common/svg/sprite.svg#arrow_left" /></svg></button>';
	const arrowNext = '<button type="button" class="slick-next"><svg class="icon"><use xlink:href="/index/common/svg/sprite.svg#arrow_right" /></svg></button>';

	// メインヴィジュアル
	document.addEventListener( 'DOMContentLoaded', function () {
		var main = new Splide('.idx-slider', {
			type: 'loop',
			rewind: true,
			pagination: false,
			arrows: false,
			autoplay: true,
  			interval: 4000,
		});

		var thumbnails = new Splide('.idx-slider-thumb', {
			perPage: 5,
			gap: 10,
			rewind: true,
			pagination: false,
			cover: true,
			isNavigation: true,
			breakpoints: {
				767: {
					perPage: undefined, // 768px以下では全てのスライドを表示
					gap: 5,
					arrows: false,
				},
			},
		});

		main.sync(thumbnails);
		main.mount();
		thumbnails.mount();

	});

	// ブランド、おすすめ
	$('.idx-recommend-slider').slick({
		infinite: true,
		speed: 1500,
		autoplay: true,
		autoplaySpeed: 5000,
		slidesToShow: 4,
		arrows: true,
		dots: false,
		prevArrow: arrowPrev,
		nextArrow: arrowNext,
		responsive: [
			{
				breakpoint: 768,
				settings: {
					slidesToShow: 1,
					appendArrows: $('.idx-recommend-slider-arrows'),
				}
			}
		]
	});

	if($('.js-topicks .pure-g li').length) {
		$('.js-topicks').addClass(v);
	}

})(window.jQuery, window.NSFD);
